<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title : "Новый документ '" + m.title + "'" }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    initType: Number,
    api: String,
    m: Object,
    hasData: { type: Boolean, default: false },
  },
  data() {
    return {
      removeDialogShow: false,
      idEdit: 0,
      suEditShow: false,
      modelReady: false,
      type: null,
      loaded: false,
      model: [],
    };
  },
  computed: {
    editObject() {
      return false;
      return this.getAccess("object.edit", {
        id: this.object_.id,
        access: this.object_?.object_access || [],
      });
    },
  },
  watch: {
    "data.operation_type"(v) {
      //   this.calcModel(v);
      this.getModel(v);
    },
  },
  created() {
    this.loaded = false;
    this.type = this.initType || null;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true;
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  methods: {
    getModel(t) {
      let type = t || this.initType || 0;
      let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
      if (this.data.status == 2) config.fieldsRO = config.fields;
      console.log("init computed model", type, this.data.status, this.hasData);
      let model = this.calcModelConfig(config);
      if (!false)
        model.forEach((el) => {
          if (this.hasData && el.name !== "seller_id") {
            el.readonly = true;
          }
          if (this.id && el.name == "date_doc") {
            el.hidden = !true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.operation_type);
      this.getModel(this.data?.operation_type);
      this.loaded = true;
    },
  },
};
</script>
